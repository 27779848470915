import React, { useRef } from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Button, Carousel, CarouselFlexContainer, CarouselNavPositionEnum, getLinkColorFromButtonColor, getPageSectionButtonColor, Heading, Link, PageSectionPadding, PageTile, Paragraph, ResponsiveProperty, ResponsivePXValue, Spacer } from '@client/components'
import { PageTileSectionFragment, useGetAppQuery, PageCardFragment } from '@hooks/api'
import { DeviceTypeEnum, PageTileVariationEnum, PageSectionBackgroundEnum } from '@uctypes/api/globalTypes'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string, $height?: string, $mobileHeight?: string, $isImageVariation: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${(props): string => props.$background ? props.$background : props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('max-width', { mobile: '100%' })}
  overflow-x: hidden;
  ${PageSectionPadding}
  
  ${(props): CSS => {
    if (props?.$isImageVariation) {
      return `
       ${ResponsivePXValue('padding-top', '16px !important')}
       ${ResponsivePXValue('padding-bottom', '16px !important')}
      `
    }
  }}
`

const PageTileContainer = styled.div<{ $numberOfRows: number, $isImageVariation: boolean }>`
  display: grid;
  grid-auto-flow: row;

  ${ResponsiveProperty('grid-template-columns', { mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' })}
  grid-template-rows: repeat(${(props): number => props.$numberOfRows}, 1fr);

  ${(props): CSS => {
    if (props?.$isImageVariation) {
      return `
        display: flex;
        align-items: center;
        justify-content: center;

        .page-tile {
          padding-top: 0;
          padding-bottom: 0;
        }

      `
    }
  }}

`

const Footer = styled.div<{ $isStandardVariation: boolean }>`
  display: flex;
  align-self: center;
  flex-direction: ${(props): string => props.$isStandardVariation ? 'row' : 'column'};
`
const CarouselContainer = styled.div`
  ${CarouselFlexContainer}
`

export interface PageTileSectionProps {
  pageSection: PageTileSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageTileSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageTileSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const theme = useTheme()

  const isStandardVariation = pageSection.tileVariation === PageTileVariationEnum.STANDARD
  const isImageVariation = pageSection.tileVariation === PageTileVariationEnum.IMAGE
  const background = pageSection?.background === PageSectionBackgroundEnum.WHITE ? theme.colors.whites.pureWhite : theme.colors.whites.desertStorm

  const pageTiles = pageSection?.pageTiles || []
  const link = pageSection?.link

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const linkColor = getLinkColorFromButtonColor('secondary', link?.color)

  let pageTile: PageCardFragment

  return (
    <Container ref={pageRef} $background={background} $isImageVariation={isImageVariation}>
      <Choose>
        <When condition={!!pageSection}>
          <If condition={!!pageSection?.title}>
            <Heading align='center' variant='h3' fontWeight={500}>{pageSection.title}</Heading>
          </If>
          <Choose>
            <When condition={!isStandardVariation && !isDesktop}>
              <Choose>
                <When condition={pageTiles.length === 1}>
                  <PageTile
                    className='page-tile'
                    key={pageTile.id}
                    pageTile={pageTiles[0]}
                    isStandardVariation={isStandardVariation}
                  />
                </When>
                <Otherwise>
                  <CarouselContainer>
                    <Carousel
                      trackingTitle={`${pageSection?.title}-page-tile-carousel`}
                      displayNavButtons={!isDesktop}
                      navPosition={CarouselNavPositionEnum.CENTER}>
                      <For each='pageTile' of={pageTiles}>
                        <PageTile
                          className='page-tile'
                          key={pageTile.id}
                          pageTile={pageTile}
                          isStandardVariation={isStandardVariation}
                          hideDescription={!isStandardVariation && !isDesktop}
                        />
                      </For>
                    </Carousel>
                  </CarouselContainer>

                </Otherwise>
              </Choose>
            </When>
            <Otherwise>
              <PageTileContainer $numberOfRows={pageTiles.length / 3} $isImageVariation={isImageVariation}>
                <For each='pageTile' of={pageTiles}>
                  <PageTile
                    className='page-tile'
                    key={pageTile.id}
                    pageTile={pageTile}
                    isStandardVariation={isStandardVariation}
                    isImageVariation={isImageVariation}
                  />
                </For>
              </PageTileContainer>
            </Otherwise>
          </Choose>
          <If condition={!!pageSection?.title}>
            <Footer $isStandardVariation={isStandardVariation}>
              <Spacer universal='48px' />
              <Choose>
                <When condition={isStandardVariation}>
                  <Paragraph variant='p1'>{pageSection?.description}</Paragraph>
                  <Spacer universal='8px' variant='horizontal' />
                  <Link variant='l1' color={linkColor} hoverColor={linkColor} href={link?.link} bold>{link?.title}</Link>
                </When>
                <Otherwise>
                  <Button color={getPageSectionButtonColor(link?.color)} href={link?.link} title={link?.title} />
                </Otherwise>
              </Choose>
            </Footer>
          </If>
        </When>
        <Otherwise>
          Page Tile Section not found
        </Otherwise>
      </Choose>
    </Container>
  )
}
