import { CartItemFragment } from '@hooks/api'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

import { ItemInterfaceNew } from './GTM'

export abstract class EventPlugin {

  abstract hasAddedPaymentMethod?(step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void
  abstract hasAddedShippingMethod?(step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void
  abstract hasAddedToCart?(products: ItemInterfaceNew, userId?: string): void
  abstract hasRemovedFromCart?(products: ItemInterfaceNew, userId?: string): void
  abstract hasBegunCheckoutStep?(step: string | number, option: string, products: CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void
  abstract hasLoggedIn?(userId: string, method: string, source: string): void
  abstract hasMadePurchase?(step: string | number, option: string, products: readonly CartItemFragment[], id: string, revenue: number, discountTitles: string, discountValue: number, userId: string): void
  abstract hasPerformedSearch?(term: string, category: ProductRangeEnum): void
  abstract hasSelectedItem?(list: string, products: ItemInterfaceNew, userId: string): void
  abstract hasSelectedMultipleItems?(list: string, products: ItemInterfaceNew[], userId: string): void
  abstract hasSignedUp?(email: string, mobile: string, method: string, firstName: string, lastName: string, newsletter: string, source: string): void
  abstract hasViewedProduct?(list: string, products: ItemInterfaceNew, userId: string): void
  abstract hasViewedMultipleProducts?(list: string, products: ItemInterfaceNew[], userId: string): void
  abstract hasViewedCatalogue?(products: ItemInterfaceNew[], userId: string): void
  abstract hasAddedCoupon?(couponName: string, couponType: string): void
  abstract hasAddedUserId?(userId: string): void
  abstract hasGeneratedLead?(email: string, mobile: string, method: string): void
  abstract hasSavedCart?(products: CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void
  abstract hasClickedElement?(elementName: string, elementPurpose?: string, userId?: string): void

}

export class UserEvents {

  static instance: UserEvents

  static shared(): UserEvents {
    if (!this.instance) {
      this.instance = new UserEvents()
    }
    return this.instance
  }

  plugins: EventPlugin[] = []

  registerPlugin(plugin: EventPlugin) {
    this.plugins.push(plugin)
  }

  hasAddedPaymentMethod(step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedPaymentMethod?.(step, option, products, value, discountTitles, discountValue, userId))
  }

  hasSavedCart(products: CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasSavedCart?.(products, value, discountTitles, discountValue, userId))
  }

  hasAddedShippingMethod(step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedShippingMethod?.(step, option, products, value, discountTitles, discountValue, userId))
  }

  hasAddedToCart(products: ItemInterfaceNew, userId?: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedToCart?.(products, userId))
  }

  hasRemovedFromCart(products: ItemInterfaceNew, userId?: string): void {
    this.plugins.forEach((plug) => plug?.hasRemovedFromCart?.(products, userId))
  }

  hasBegunCheckoutStep(step: string | number, option: string, products: CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasBegunCheckoutStep?.(step, option, products, value, discountTitles, discountValue, userId))
  }

  hasLoggedIn(userId:string, method: string, source: string): void {
    this.plugins.forEach((plug) => plug?.hasLoggedIn?.(userId, method, source))
  }

  hasMadePurchase(step: string | number, option: string, products: readonly CartItemFragment[], id: string, revenue: number, discountTitles: string, discountValue: number, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasMadePurchase?.(step, option, products, id, revenue, discountTitles, discountValue, userId))
  }

  hasPerformedSearch(term: string, category: ProductRangeEnum): void {
    this.plugins.forEach((plug) => plug?.hasPerformedSearch?.(term, category))
  }

  hasGeneratedLead(email: string, mobile: string, method: string): void {
    this.plugins.forEach((plug) => plug?.hasGeneratedLead?.(email, mobile, method))
  }

  hasSelectedItem(list: string, products: ItemInterfaceNew, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasSelectedItem?.(list, products, userId))
  }

  hasSelectedMultipleItems(list: string, products: ItemInterfaceNew[], userId: string): void {
    this.plugins.forEach((plug) => plug?.hasSelectedMultipleItems?.(list, products, userId))
  }

  hasSignedUp(email: string, mobile: string, method: string, firstName: string, lastName: string, newsletter: string, source: string): void {
    this.plugins.forEach((plug) => plug?.hasSignedUp?.(email, mobile, method, firstName, lastName, newsletter, source))
  }

  hasViewedProduct(list: string, products: ItemInterfaceNew, userId: string): void {
    this.plugins.forEach((plug) => plug?.hasViewedProduct?.(list, products, userId))
  }

  hasClickedElement(elementName: string, elementPurpose?: string, userId?: string): void {
    this.plugins.forEach((plug) => plug?.hasClickedElement?.(elementName, elementPurpose, userId))
  }

  hasViewedMultipleProducts(list: string, products: ItemInterfaceNew[], userId: string): void {
    this.plugins.forEach((plug) => plug?.hasViewedMultipleProducts?.(list, products, userId))
  }

  hasViewedCatalogue(products: ItemInterfaceNew[], userId: string): void {
    this.plugins.forEach((plug) => plug?.hasViewedCatalogue?.(products, userId))
  }

  hasAddedCoupon(couponName: string, couponType: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedCoupon?.(couponName, couponType))
  }

  hasAddedUserId(userId: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedUserId?.(userId))
  }

}
