import React, { Fragment } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Seperator } from '@atoms/index'
import { CraftKidsCard, SectionLoading, Spacer } from '@client/components'
import { Carousel } from '@client/components/molecules/misc/Carousel/Carousel'
import { CarouselCardWidth, CarouselFlexContainer, CarouselSlideMargin, CarouselContainerWidth, ResponsivePXValue } from '@components/Theme'
import { FrozenMealDishListFragment, useGetAllFrozenCategoriesQuery, useGetAllFrozenMealDishesQuery, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum, FrozenCategoryStatusEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
 
  ${CarouselContainerWidth}

  ${ResponsivePXValue('margin', '24px 0')}
  
  .title {
    padding-top: 0;
  }
`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}

  .craft-kids-card {
   ${CarouselCardWidth}
  }
`

const CardContainer = styled.div`
  display: flex;
  align-self: center;
 
  ${CarouselCardWidth}

  .craft-kids-card {
     ${CarouselSlideMargin}
  }
`

export interface CraftKidsInStoreCarouselProps {
  title?: string
  skeleton?: boolean
}

export function CraftKidsInStoreCarousel({ title }: CraftKidsInStoreCarouselProps): JSX.Element {

  const { data: frozenCategories } = useGetAllFrozenCategoriesQuery({ variables: { filters: { status: [FrozenCategoryStatusEnum.ACTIVE] } } })

  const variables = {
    filters: {
      frozenCategories: [frozenCategories?.categories?.list?.find((category) => { return category?.title === 'Craft Kids' })?.id],
    },
  }

  const { data, loading } = useGetAllFrozenMealDishesQuery({ variables })

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const theme = useTheme()

  const dishes = data?.dishes?.list ? data?.dishes?.list : []

  let craftKidsMeal: FrozenMealDishListFragment

  return (
    <Container id='craftKidsMealOnMenuCarousel'>
      <Choose>
        <When condition={!!dishes}>
          <Fragment>
            <If condition={!!title}>
              <Heading variant='h4' align='center' className='title' color={theme.colors.slates.bitter}>{title}</Heading>
              <Spacer universal='8px' />
              <Seperator align='center' />
            </If>
            <Choose>
              <When condition={dishes.length === 1}>
                <CardContainer>
                  <CraftKidsCard
                    className='craft-kids-card'
                    key={craftKidsMeal.id}
                    craftKidsMeal={craftKidsMeal} />
                </CardContainer>
              </When>
              <When condition={isDesktop && dishes.length < 4}>
                <CardContainer>
                  <For each='craftKidsMeal' of={dishes}>
                    <CraftKidsCard
                      className='craft-kids-card'
                      key={craftKidsMeal.id}
                      craftKidsMeal={craftKidsMeal} />
                  </For>
                </CardContainer>
              </When>
              <Otherwise>
                <CarouselContainer>
                  <Carousel
                    trackingTitle={`${title}-craft-kids-meal-on-menu-carousel`}
                    displayNavButtons={isDesktop}>
                    <For each='craftKidsMeal' of={dishes}>
                      <CraftKidsCard
                        className='embla-slide craft-kids-card'
                        key={craftKidsMeal.id}
                        craftKidsMeal={craftKidsMeal} />
                    </For>
                  </Carousel>
                </CarouselContainer>
              </Otherwise>
            </Choose>
          </Fragment>
        </When>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <Otherwise></Otherwise>
      </Choose>
    </Container>
  )

}
