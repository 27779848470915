import React, { useRef } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Carousel, CarouselFlexContainer, CarouselMaxWidth, CarouselSlideMargin, Heading, PageSectionPadding, ResponsivePXValue, Spacer } from '@client/components'
import { PageTextCardSectionFragment, useGetAppQuery, PageTextCardFragment } from '@hooks/api'
import { DeviceTypeEnum, PageSectionBackgroundEnum } from '@uctypes/api/globalTypes'

import { PageSectionRef } from './PageSectionGroup'
import { PageTextCard } from './PageTextCard'

const Container = styled.div<{ $background: string }>`
 display: flex;
 flex-direction: column;
 flex: 1;

  ${PageSectionPadding}

  background: ${(props): string => props.$background ? props.$background : props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('max-width', { mobile: '100%' })}
  overflow-x: hidden;

  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '20px', desktop: '55px' })}
  
`
const PageTextCardContainer = styled.div`
  display: flex;
  align-self: center;
 
  ${CarouselMaxWidth}

  .page-text-card {
    ${CarouselSlideMargin}
  }
`
const CarouselContainer = styled.div`
  ${CarouselFlexContainer}
`
export interface PageTextCardSectionProps {
  pageSection: PageTextCardSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageTextCardSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageTextCardSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const theme = useTheme()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA

  const background = pageSection.background === PageSectionBackgroundEnum.WHITE ? theme.colors.whites.alabaster : theme.colors.whites.desertStorm

  const pageTextCards = pageSection?.pageTextCards || []

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  let index: number
  let pageTextCard: PageTextCardFragment

  return (
    <Container ref={pageRef} $background={background}>
      <Choose>
        <When condition={!!pageSection}>
          <If condition={!!pageSection?.title}>
            <Heading align='center' variant='h4' color={theme.colors.slates.bitter}>{pageSection.title}</Heading>
            <Spacer universal='24px' />
          </If>
          <Choose>
            <When condition={pageTextCards.length === 1}>
              <PageTextCardContainer>
                <PageTextCard
                  className='page-text-card'
                  pageTextCard={pageSection.pageTextCards[0]}
                  key={index} />
              </PageTextCardContainer>
            </When>
            <When condition={isDesktop && pageTextCards.length < 4}>
              <PageTextCardContainer>
                <For each='pageTextCard' of={pageTextCards} index='index'>
                  <PageTextCard
                    className='page-text-card'
                    pageTextCard={pageTextCard}
                    key={index} />
                </For>
              </PageTextCardContainer>
            </When>
            <Otherwise>
              <CarouselContainer>
                <Carousel trackingTitle={`${pageSection?.title}-page-text-card-carousel`}>
                  <For each='pageTextCard' of={pageTextCards} index='index'>
                    <PageTextCard
                      className='embla-slide'
                      pageTextCard={pageTextCard}
                      key={index} />
                  </For>
                </Carousel>
              </CarouselContainer>
            </Otherwise>
          </Choose>
        </When>
        <Otherwise>
          Page TextCard Section not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
