import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Paragraph, Heading, scrollToLink, Button, getPageSectionButtonColor, Spacer } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { PageBannerFragment } from '@hooks/api'

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('max-height', '500px')}
  ${ResponsivePXValue('height', '440px')}
`

const ContentContainer = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${ResponsivePXValue('max-width', { desktop: '650px' })}
  ${ResponsivePXValue('padding', '0 192px')}
  ${ResponsivePXValue('border-radius', '6px')}

  .banner-title {
    letter-spacing: 1px;
    font-weight: 600;
  }

`

const ImageContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

export interface DesktopPageBannerHeroProps {
  pageBanner: PageBannerFragment
}

export function DesktopPageBannerHero({ pageBanner }: DesktopPageBannerHeroProps): JSX.Element {

  const navigate = useNavigate()
  const events = useEvents()
  const config = useConfig()

  const linkUrl = pageBanner?.link?.link
  const linkColor = getPageSectionButtonColor(pageBanner?.link?.color)
  const linkTitle = pageBanner?.link?.title
  const description = pageBanner?.description

  const _handleLinkClick = (): void => {
    events.hasClickedElement(`page-banner-hero-${linkTitle}`, linkUrl)
    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={pageBanner.image} objectFit={pageBanner?.objectFit} />
      </ImageContainer>
      <ContentContainer>
        <Choose>
          <When condition={!!description}>
            <Heading variant='h1' align='center' className='banner-title' color={pageBanner.titleColor}>{pageBanner.bannerTitle}</Heading>
            <If condition={!!pageBanner.description}>
              <Spacer universal='32px' />
              <Paragraph align='center' variant='p1' color={pageBanner.descriptionColor}>{description} </Paragraph>
            </If>
            <Spacer universal='32px' />
          </When>
          <Otherwise>
            <Heading variant='h1' align='center' className='banner-title' fontWeight={500} color={pageBanner.titleColor}>{pageBanner.bannerTitle}</Heading>
            <If condition={!!pageBanner.subTitle}>
              <Spacer universal='32px' />
              <Heading variant='h5' align='center' fontWeight={500} color={pageBanner.subTitleColor}>{pageBanner.subTitle}</Heading>
            </If>
            <Spacer universal='32px' />
          </Otherwise>
        </Choose>
        <If condition={!!linkTitle}>
          <Button className='button' title={linkTitle} color={linkColor} href={linkUrl} onClick={_handleLinkClick} />
        </If>
      </ContentContainer>
    </Container>
  )
}
