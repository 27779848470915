import React, { Fragment } from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading } from '@client/components/atoms/typography/Heading'
import { Carousel, CarouselAnchor } from '@client/components/molecules/misc/Carousel/Carousel'
import { CarouselFlexContainer, ResponsivePXValue } from '@components/Theme'
import { MealKitCategoryFragment, useActiveMenuDishesQuery, useGetAppQuery, ActiveMenuDishesQuery, MealKitDishListFragment } from '@hooks/api'
import { MealKitMenuCard, SectionLoading } from '@molecules/index'
import { lunchPremiumId, lunchSaverId } from '@pages/MealKitStore'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  display: flex;
  flex-direction: column;
 
  ${ResponsivePXValue('max-width', '1136px')}
  
  .title {
    padding-top: 0;
  }

  .anchor-carousel {
    font-family: 'gordita';
    ${ResponsivePXValue('font-size', { mobile: '8px', tablet: '8px', desktop: '10px' })}
    ${ResponsivePXValue('line-height', '16px')}
  }
`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}

  .nav-button {
     top: 36%;
     transform: translateY(-36%);
  }

  .meal-kit-menu-card {
     ${ResponsivePXValue('width', { desktop: '264px' })}
     ${ResponsivePXValue('height', { desktop: '224px' })}
     ${ResponsivePXValue('min-height', { desktop: '224px' })}
     ${ResponsivePXValue('margin', { desktop: '6px !important' })}
  }

  .anchor-carousel {
    font-weight: 400;
    ${ResponsivePXValue('font-size', { desktop: '14px' })}
    ${ResponsivePXValue('line-height', { desktop: '22px' })}
    ${ResponsivePXValue('margin-top', { mobile: '24px', desktop: '12px' })}
  }

`

const CardContainer = styled.div`
  display: flex;
  align-self: center;

  ${ResponsivePXValue('width', { desktop: '252px' })}
  ${ResponsivePXValue('height', { desktop: '224px' })}
  ${ResponsivePXValue('min-height', { desktop: '224px' })}

  .meal-kit-menu-card {
    ${ResponsivePXValue('margin', { desktop: '4px !important' })}
  }
`
export interface MealKitOnMenuThisWeekProps {
  categories: MealKitCategoryFragment[]
  title?: string
}

export function MealKitOnMenuThisWeek({ categories, title }: MealKitOnMenuThisWeekProps): JSX.Element {

  const categoryIds: string[] = []
  const anchors: CarouselAnchor[] = []

  categories.filter(category => category.id !== lunchPremiumId && category.id !== lunchSaverId).forEach((category: MealKitCategoryFragment, index) => {
    categoryIds.push(category.id)
    anchors.push({ text: category.title, index })
  })

  const variables = {
    filters: {
      categories: categoryIds,
    },
  }

  const { data, loading } = useActiveMenuDishesQuery({ variables })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const maxDishesToDisplay = isDesktop ? 24 : 6

  const menuDishes: ActiveMenuDishesQuery['activeMenu']['menuDishes'] = data?.activeMenu?.menuDishes
  const dishes: ActiveMenuDishesQuery['activeMenu']['menuDishes']['list'] = menuDishes?.list?.slice(0, maxDishesToDisplay) ? [...menuDishes?.list?.slice(0, maxDishesToDisplay)] : []

  let mealKit: MealKitDishListFragment

  const categorySorter: { [k: string]: number } = {
    ['Adventurous Foodie'.toLowerCase()]: 0,
    ['Quick & Easy'.toLowerCase()]: 1,
    ['Carb Conscious'.toLowerCase()]: 2,
    ['Fan Faves'.toLowerCase()]: 3,
    ['Veggie'.toLowerCase()]: 4,
    ['*New Simple & Save'.toLowerCase()]: 5,
  }

  const sortedDishes = dishes.filter(({ mealKitCategories }) => {
    const { id } = mealKitCategories[0]
    return id !== lunchPremiumId && id !== lunchSaverId
  }).sort(({ mealKitCategories: [{ title: titleA }] }, { mealKitCategories: [{ title: titleB }] }) => {
    const rankA = categorySorter[titleA.toLowerCase()]
    const rankB = categorySorter[titleB.toLowerCase()]
    return rankA - rankB
  })

  return (
    <Container id='mealKitOnMenuThisWeek'>
      <Choose>
        <When condition={!!sortedDishes}>
          <Fragment>
            <If condition={!!title}>
              <Heading variant='h3' fontWeight={500} align='center'>{title}</Heading>
            </If>
            <Choose>
              <When condition={sortedDishes.length === 1}>
                <CardContainer>
                  <MealKitMenuCard
                    className='meal-kit-menu-card'
                    key={mealKit.id}
                    mealKit={mealKit} />
                </CardContainer>
              </When>
              <When condition={isDesktop && sortedDishes.length < 4}>
                <CardContainer>
                  <For each='mealKit' of={sortedDishes}>
                    <MealKitMenuCard
                      className='meal-kit-menu-card'
                      key={mealKit.id}
                      mealKit={mealKit} />
                  </For>
                </CardContainer>
              </When>
              <Otherwise>
                <CarouselContainer>
                  <Carousel
                    trackingTitle={`${title}-meal-kit-on-menu-this-week-carousel`}
                    slidesToScroll={4}
                    anchors={anchors}
                    displayNavButtons={isDesktop}>
                    <For each='mealKit' of={sortedDishes}>
                      <MealKitMenuCard
                        className='embla-slide meal-kit-menu-card'
                        key={mealKit.id}
                        mealKit={mealKit} />
                    </For>
                  </Carousel>
                </CarouselContainer>
              </Otherwise>
            </Choose>
          </Fragment>
        </When>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <Otherwise></Otherwise>
      </Choose>
    </Container>
  )
}
