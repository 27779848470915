import React, { useState, useEffect, useCallback } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import styled, { CSS, css } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, IconEnum, SmallLoader, Paragraph, Button } from '@atoms/index'
import { CarouselMaxWidth, CarouselSlideMargin, FadeEffect, ResponsivePXValue, theme, UserSelectNone, ZeroSpace } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { useRecursiveTimeout } from './useRecursiveTimeout'

export type CarouselNavIconColor = 'orange' | 'grey' | 'slate' | 'white' | 'transparent'

export enum CarouselNavPositionEnum {
  TOP = 'TOP',
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  

  .title {
    ${ResponsivePXValue('margin', { mobile: '0 20px 24px', tablet: '0 50px 24px', desktop: '0 80px 24px' })}
  }

  .embla {
    position: relative;
  }

  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }

  .embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }

  .embla__viewport.is-dragging {
    cursor: grabbing;
  }

  .embla__container {
    display: flex;
  
    ${UserSelectNone}
  }

  .embla__slide {
    position: relative;
    display: flex;

    .embla-slide {
      ${CarouselSlideMargin}
    }

    &:first-of-type .embla-slide {
      ${ResponsivePXValue('margin-left', { mobile: '10px', tablet: '14px', desktop: '0' })}
    }

    &:last-of-type .embla-slide {
      ${ResponsivePXValue('margin-right', { mobile: '10px', tablet: '14px', desktop: '16px' })}
    }
  }
`

const TopNavPosition = css`
  top: 0;
`
const CenterNavPosition = css`
  top: 50%;
  transform: translateY(-50%);
`
const BottomNavPosition = css`
  bottom: 28px;
`

const ButtonsContainer = styled.div<{ $position: CarouselNavPositionEnum, $left: string, $right: string, $previousDisabled: boolean, $nextDisabled: boolean }>`

  .nav-button {
    outline: 0;
    cursor: pointer;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    border: 0;
    justify-content: center;
    ${ResponsivePXValue('align-items', { mobile: 'flex-end', tablet: 'center', desktop: 'center' })}
    
    &[disabled] {
      background-color: transparent;
      opacity: 0.5;
      cursor: not-allowed;
    }

    ${(props): CSS => {
    if (props?.$position) {
      switch (props.$position) {
        case CarouselNavPositionEnum.TOP:
          return TopNavPosition
        case CarouselNavPositionEnum.CENTER:
          return CenterNavPosition
        case CarouselNavPositionEnum.BOTTOM:
          return BottomNavPosition
        default:
          return CenterNavPosition
      }
    }
  }}
  
  }

  .left {
    ${(props): CSS => props.$left ? ResponsivePXValue('left', props.$left) : ResponsivePXValue('left', { mobile: '5%', tablet: '5%', desktop: '-45px' })};
  }
  
  .right {
    ${(props): CSS => props.$right ? ResponsivePXValue('right', props.$right) : ResponsivePXValue('right', { mobile: '5%', tablet: '5%', desktop: '-45px' })};
  }
`
const Embla = styled.div<{ $maxWidth: string, $skipDesktop: boolean }>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${(props): CSS => props.$maxWidth ? ResponsivePXValue('max-width', { mobile: props.$maxWidth, tablet: props.$maxWidth, desktop: props.$maxWidth }, { desktop: props.$skipDesktop }) : CarouselMaxWidth};
`

const DotButtonContainer = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 16px;
`

const EmblaDotButton = styled.button<{ $selected: boolean }>`
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  display: flex;
  align-items: center;

  background-color: ${(props): string => props.theme.colors.misc.transparent};

  &:after {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  content: "";

  background-color: ${(props): string => props.$selected ? props.theme.colors.oranges.coral : props.theme.colors.slates.celeste};
 }
`

const PagingInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('padding-top', '10px')}
  ${ResponsivePXValue('padding-left', { mobile: '16px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('padding-right', { mobile: '16px', tablet: '32px', desktop: '32px' })}

  .paging-info {
    ${ZeroSpace}

    display: flex;
    justify-content: space-between;

    ${ResponsivePXValue('width', '48px')}
  }
`
const Scrollpiece = styled.div`

`
const Of = styled.div`
  display: flex;
`
const PagingNumber = styled.div`
  display: flex;
`
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '382px')}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
`
const AnchorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Anchor = styled.div<{ $active: boolean }>`
  display: flex;
  cursor: pointer;

  color: ${(props): string => props.$active ? props.theme.colors.oranges.burntSienna : props.theme.colors.greys.mineshaft};

  ${ResponsivePXValue('margin', '24px')}
`

const Fade = styled.div`
  ${FadeEffect()}
`

export interface DotButtonProps {
  selected?: boolean
  onClick?: () => void
}

export const DotButton = ({ selected, onClick }: DotButtonProps): JSX.Element => (
  <EmblaDotButton
    $selected={selected}
    type="button"
    onClick={onClick} />
)

export interface CarouselAnchor {
  text: string
  index: number
}
export interface CarouselProps {
  children: JSX.Element | JSX.Element[]
  title?: string
  autoPlay?: boolean
  displayNavButtons?: boolean
  displayPagingInfo?: boolean
  displayProgress?: boolean
  displayFade?: boolean
  startIndex?: number
  emblaClass?: string
  progressContainerClass?: string
  navPosition?: CarouselNavPositionEnum
  navButtonLeft?: string
  navButtonRight?: string
  navIconColor?: CarouselNavIconColor
  maxWidth?: string
  skipDesktop?: boolean
  slidesToScroll?: number | null
  loop?: boolean
  anchors?: CarouselAnchor[]

  trackingTitle?: string
}
interface CarouselState {
  selectedIndex: number
  scrollSnaps: number[]
  scrollProgress: number
  maxIndex: number
  itemsToDisplay: number
  previousDisabled: boolean
  nextDisabled: boolean
}

const DEFAULT_STATE: CarouselState = {
  selectedIndex: 0,
  scrollSnaps: [],
  scrollProgress: 0,
  maxIndex: 0,
  itemsToDisplay: 3,
  previousDisabled: true,
  nextDisabled: false,
}

export function Carousel({
  children,
  title,
  autoPlay = false,
  displayNavButtons,
  displayPagingInfo = false,
  displayProgress = true,
  displayFade = false,
  startIndex = 0,
  emblaClass,
  progressContainerClass,
  navPosition = CarouselNavPositionEnum.CENTER,
  navButtonLeft,
  navButtonRight,
  navIconColor = 'grey',
  maxWidth,
  skipDesktop = false,
  slidesToScroll,
  loop = false,
  anchors,
  trackingTitle,
}: CarouselProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<CarouselState>({ ...DEFAULT_STATE })
  const events = useEvents()

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const emblaAlign = navPosition === CarouselNavPositionEnum.BOTTOM || isMobile ? 'center' : 'start'
  if (isMobile) {
    navPosition = CarouselNavPositionEnum.BOTTOM
  }
  const totalSlide = (children as []).length
  const numberOfSlidesToScroll = slidesToScroll ?? (autoPlay ? 1 : (isDesktop ? (totalSlide > 3 ? 3 : 1) : 1))
  // @ts-expect-error - if trackingTitle is not provided, use the first child's className
  const gaTitle = trackingTitle || children?.find(child => child)?.props?.className || 'none'

  const [emblaRef, emblaApi] = useEmblaCarousel({
    startIndex,
    align: emblaAlign,
    containScroll: isMobile ? undefined : 'keepSnaps',
    // dragFree: true,
    slidesToScroll: numberOfSlidesToScroll,
    loop,
  })

  const AUTOPLAY_INTERVAL = 4000

  const autoplay = useCallback(() => {

    if (!emblaApi) return

    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext()
    } else {
      emblaApi.scrollTo(0)
    }
  }, [emblaApi])

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL)

  const currentSlide = emblaApi?.selectedScrollSnap() + 1 || 0

  const onSelect = useCallback(() => {

    if (!emblaApi) return

    const previousDisabled = !emblaApi.canScrollPrev()
    const nextDisabled = !emblaApi.canScrollNext()
    const selectedIndex = emblaApi.selectedScrollSnap()

    setState((prevState) => ({ ...prevState, selectedIndex, previousDisabled, nextDisabled }))

  }, [emblaApi])

  const _scrollNext = useCallback(() => {
    if (!emblaApi.canScrollNext()) return
    events.hasClickedElement('carousel-next-click', gaTitle)
    emblaApi.scrollNext()
    stop()
  }, [emblaApi, stop])

  const _scrollPrevious = useCallback(() => {
    if (!emblaApi.canScrollPrev()) return
    emblaApi.scrollPrev()
    events.hasClickedElement('carousel-previous-click', gaTitle)
    stop()
  }, [emblaApi, stop])

  const _scrollTo = useCallback((index: number) => {
    if (emblaApi) {
      emblaApi.scrollTo(index)
    }
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      onSelect()
      setState((prevState) => ({ ...prevState, scrollSnaps: emblaApi.scrollSnapList() }))
      emblaApi.on('select', onSelect)

      if (autoPlay) {
        emblaApi.on('pointerDown', stop)
      }

    }
  }, [emblaApi, onSelect, stop])

  useEffect(() => {
    if (autoPlay) {
      play()
    }
  }, [play])

  let anchor: CarouselAnchor

  return (
    <Choose>
      <When condition={totalSlide < 1}>
        <LoadingContainer>
          <LoaderBox>
            <SmallLoader color={theme.colors.oranges.coral} />
          </LoaderBox>
        </LoadingContainer>
      </When>
      <Otherwise>
        <Container>
          <If condition={!!title}>
            <Heading variant='h1' className='title'>{title}</Heading>
          </If>
          <If condition={!!anchors && anchors.length > 0}>
            <AnchorContainer>
              <For each='anchor' of={anchors}>
                <Anchor className='anchor-carousel' $active={anchor.index === state.selectedIndex} key={anchor.index} onClick={() => _scrollTo(anchor.index)}>{anchor.text}</Anchor>
              </For>
            </AnchorContainer>
          </If>
          <Embla
            className={`embla ${emblaClass}`}
            $maxWidth={maxWidth}
            $skipDesktop={skipDesktop}>
            <Scrollpiece className='embla__viewport' ref={emblaRef}>
              <div className='embla__container'>
                {React.Children.map(children, (child, index) => {
                  return <div className='embla__slide' key={index} /* onClick={(e) => onSlideClick(e)} */>
                    {child}
                  </div>
                })}
              </div>
            </Scrollpiece>
            <If condition={displayNavButtons}>
              <ButtonsContainer
                $position={navPosition}
                $left={navButtonLeft}
                $right={navButtonRight}
                $previousDisabled={state.previousDisabled}
                $nextDisabled={state.nextDisabled}>
                <Button
                  className='nav-button left'
                  color='black'
                  icon={IconEnum.CHEVRON_BACK_OUTLINE}
                  iconColor={navIconColor}
                  size='small'
                  disabled={state.previousDisabled}
                  onClick={_scrollPrevious} />
                <Button
                  className='nav-button right'
                  color='black'
                  icon={IconEnum.CHEVRON_FORWARD_OUTLINE}
                  iconColor={navIconColor}
                  size='small'
                  disabled={state.nextDisabled}
                  onClick={_scrollNext} />
              </ButtonsContainer>
            </If>
            <If condition={displayPagingInfo}>
              <PagingInfo>
                <Paragraph
                  variant='p3'
                  className='paging-info'
                  color={theme.colors.slates.ironsideGrey}
                  align='center'>
                  <PagingNumber>{currentSlide}</PagingNumber>
                  <Of> of </Of>
                  <PagingNumber>{totalSlide}</PagingNumber>
                </Paragraph>
              </PagingInfo>
            </If>
            <If condition={!displayPagingInfo && displayProgress}>
              <DotButtonContainer className={progressContainerClass}>
                {state.scrollSnaps.map((_, index) => (
                  <DotButton
                    key={index}
                    selected={index === state.selectedIndex}
                    onClick={() => _scrollTo(index)}
                  />
                ))}
              </DotButtonContainer>
            </If>
            <If condition={displayFade}>
              <Fade />
            </If>
          </Embla>
        </Container>
      </Otherwise>
    </Choose>
  )
}
