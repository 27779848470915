import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Paragraph, Heading, scrollToLink, FlexDeviceContainer, Button, getPageSectionButtonColor } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { PageBannerFragment, useUserDetailsQuery } from '@hooks/api'
import { PageBannerBackgroundColorEnum, PageBannerVariationEnum } from '@uctypes/api/globalTypes'

const MainContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  width: 100%;

  background-color: ${(props): string => props.$backgroundColor};

  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('justify-content', { mobile: 'flex-start', tablet: 'flex-end', desktop: 'flex-end' })}
  ${ResponsivePXValue('align-items', { mobile: 'stretch', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('height', { mobile: 'auto', tablet: '440px', desktop: '440px' })}
`

const ContentContainer = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;

  ${ResponsivePXValue('flex-grow', { mobile: '0', tablet: '1', desktop: '1' })}
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('max-width', { mobile: '100%', tablet: '445px', desktop: '445px' })}
  ${ResponsivePXValue('height', { mobile: '280px', tablet: '100%', desktop: '100%' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px 64px 16px', tablet: '48px', desktop: '48px' })}

  .banner-text {
    font-weight: 400;
    ${ResponsivePXValue('text-align', { mobile: 'center', tablet: 'left', desktop: 'left' })}
  }

  .button {
    ${ResponsivePXValue('min-height', '40px')}
  }
`

const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${ResponsivePXValue('height', { mobile: '213px', tablet: '100%', desktop: '100%' })}
  ${ResponsivePXValue('border-bottom-left-radius', { mobile: '0', tablet: '0', desktop: '64px' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '811px' })}
`

const getPageBannerBackgroundColor = (color: PageBannerBackgroundColorEnum): string => {
  switch (color) {
    case PageBannerBackgroundColorEnum.TUNDORA:
      return theme.colors.greys.tundora
    case PageBannerBackgroundColorEnum.BOULDER:
      return theme.colors.greys.boulder
    case PageBannerBackgroundColorEnum.BITTER:
      return theme.colors.slates.bitter
    case PageBannerBackgroundColorEnum.ASH:
      return theme.colors.slates.ash
    case PageBannerBackgroundColorEnum.CHETWODE_BLUE:
      return theme.colors.blues.chetwodeBlue
    case PageBannerBackgroundColorEnum.GREEN_SMOKE:
      return theme.colors.greens.greenSmoke
    case PageBannerBackgroundColorEnum.BURNT_SIENNA:
      return theme.colors.oranges.burntSienna
    case PageBannerBackgroundColorEnum.SELECTIVE_YELLOW:
      return theme.colors.yellows.selectiveYellow
  }
}

export interface PageBannerAdCampaignProps {
  pageBanner: PageBannerFragment
}

export function PageBannerAdCampaign({ pageBanner }: PageBannerAdCampaignProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const events = useEvents()

  const linkUrl = pageBanner?.link?.link
  const linkTitle = pageBanner?.link?.title
  const linkColor = getPageSectionButtonColor(pageBanner?.link?.color)

  const _handleLinkClick = (): void => {
    if (linkUrl) {
      events.hasClickedElement(`page-banner-ad-campaign-click-${linkTitle}`, linkUrl, userDetailsData?.currentUser?.id)
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  const isCampaign = pageBanner.variation === PageBannerVariationEnum.CAMPAIGN_BANNER

  return (
    <MainContainer $backgroundColor={getPageBannerBackgroundColor(pageBanner?.backgroundColor)}>
      <ContentContainer>
        <Choose>
          <When condition={isCampaign}>
            <Heading variant='h1' fontWeight={400} color={theme.colors.whites.pureWhite} className='banner-text'>{pageBanner?.bannerTitle}</Heading>
            <Paragraph variant='p1' color={theme.colors.whites.pureWhite} className='banner-text'>{pageBanner?.description}</Paragraph>
          </When>
          <Otherwise>
            <Heading variant='h4' fontWeight={400} className='banner-text'>{pageBanner?.bannerTitle}</Heading>
            <Heading variant='h1' fontWeight={400} className='banner-text'>{pageBanner?.description}</Heading>
          </Otherwise>
        </Choose>
        <If condition={!!linkTitle}>
          <Button
            className='button'
            variant='secondary'
            color={linkColor}
            title={linkTitle}
            href={linkUrl}
            onClick={_handleLinkClick} />
        </If>
      </ContentContainer>
      <ImageContainer>
        <FlexDeviceContainer $mobile $tablet>
          <ResponsiveImage
            image={pageBanner?.mobileAdCampaignImage}
            objectFit={pageBanner?.objectFit} />
        </FlexDeviceContainer>
        <FlexDeviceContainer $desktop>
          <ResponsiveImage
            image={pageBanner?.adCampaignImage}
            objectFit={pageBanner?.objectFit} />
        </FlexDeviceContainer>
      </ImageContainer>
    </MainContainer>
  )
}
