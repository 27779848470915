import {
  AddToCartEventInterface, CheckoutInterface, GenerateLeadInterface, ItemListInterface, PaymentInfoInterface,
  PurchaseInterface, RemoveFromCartInterface, SelectItemInterface, ShippingInfoInterface, UserIdInterface, ViewItemInterface,
  ItemsInterface,
} from 'sih-gtm/build/TagManager'

import { CartItemFragment } from '@hooks/api'
import { convertEnumToNum, convertEnumToNumFRZN } from '@molecules/stores'
import { NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

import { CartSaveInterface, ClickElementInterface, CouponDetailsInterface, GTMBase, LoginInfoInterface, SearchItemInterface, SignupInfoInterface } from './GTMBase'
import { EventPlugin } from './UserEvents'

export interface ItemInterfaceNew extends ItemsInterface {
  item_image?: string
  item_group_id?: string
  item_group_name?: string
  item_stock_count?: number
  is_mealkit?: string
  item_chef?: string
  item_serving_size?: number
}

export class GTM extends GTMBase implements EventPlugin {

  private getCartProductQuantities = (cartItems: readonly CartItemFragment[]): number => {

    let productQuantities = 0
    for (const i in cartItems) {
      const cartItem = cartItems[i]
      productQuantities = productQuantities + cartItem?.quantity
    }
    return productQuantities
  }

  private createCartProducts = (cartItems: readonly CartItemFragment[]): ItemInterfaceNew[] => {
    const logProducts = [] as ItemInterfaceNew[]

    for (const i in cartItems) {
      const cartItem = cartItems[i]

      switch (cartItem.product.__typename) {

        case 'MealKit':
          const servingSizeMK = convertEnumToNum(cartItem?.product?.portionSize as unknown as NumberOfPortionsEnum)

          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            item_group_id: cartItem?.product?.group?.id,
            price: cartItem?.price,
            item_brand: 'UCOOK',
            item_category: cartItem?.product?.mealKitCategories?.map((cat: any) => cat.id).join(', '),
            item_variant: cartItem?.product?.mealKitCategories?.map((cat: any) => cat.title).join(', '),
            item_list_name: 'Meal Kit',
            quantity: cartItem?.quantity,
            item_image: cartItem?.product?.coverImage?.location,
            item_stock_count: 1,
            item_chef: cartItem?.product?.chef?.name,
            item_serving_size: servingSizeMK,
            is_mealkit: 'yes',
          })
          break

        case 'FrozenMeal':
          const servingSizeFRZ = convertEnumToNumFRZN(cartItem?.product?.frozenPortionSize)

          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            item_group_id: cartItem?.product?.group?.id,
            price: cartItem?.product?.price,
            item_brand: 'UCOOK',
            item_category: cartItem?.product?.frozenCategories?.map((cat: any) => cat.id).join(', '),
            item_variant: cartItem?.product?.frozenCategories?.map((cat: any) => cat.title)?.join(', '),
            item_list_name: 'Craft Meals',
            quantity: cartItem?.quantity,
            item_image: cartItem?.product?.coverImage?.location,
            item_stock_count: 1,
            item_chef: 'UCOOK',
            item_serving_size: servingSizeFRZ,
            is_mealkit: 'no',
          })
          break

        case 'Wine':
          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            item_group_id: cartItem?.product?.id,
            price: cartItem?.product?.price,
            item_brand: cartItem?.product?.vineyard?.name,
            item_category: cartItem?.product?.wineCategory?.id,
            item_variant: cartItem?.product?.wineCategory?.title,
            item_list_name: 'Wines',
            quantity: cartItem?.quantity,
            item_image: cartItem?.product?.coverImage?.location,
            item_stock_count: 1,
            item_chef: 'UCOOK',
            item_serving_size: 1,
            is_mealkit: 'no',
          })
          break
        case 'MarketProduct':
          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            item_group_id: cartItem?.product?.id,
            price: cartItem?.product?.price,
            item_brand: cartItem?.product?.brand?.name,
            item_category: cartItem?.product?.marketProductCategories[0]?.id,
            item_variant: cartItem?.product?.marketProductCategories[0]?.title,
            item_list_name: 'MarketProduct',
            quantity: cartItem?.quantity,
            item_image: cartItem?.product?.coverImage?.location,
            item_stock_count: 1,
            item_chef: 'UCOOK',
            item_serving_size: 1,
            is_mealkit: 'no',
          })
          break
      }
    }

    return logProducts
  }

  hasAddedPaymentMethod(step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void {

    const gaProducts = this.createCartProducts(products)

    try {
      const eventData: PaymentInfoInterface = {
        event: 'add_payment_info',
        eventParams: {
          currency: 'ZAR',
          value,
          coupon: discountTitles,
          // shipping_tier: 'next day, air,ground'
          items: gaProducts,
        },
        customEventParams: {
          totalDiscountAmount: discountValue,
          user_id: userId,
        },
      }
      this.addPaymentInfo(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasAddedShippingMethod(step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void {

    const gaProducts = this.createCartProducts(products)

    try {
      const eventData: ShippingInfoInterface = {
        event: 'add_shipping_info',
        eventParams: {
          currency: 'ZAR',
          value,
          coupon: discountTitles,
          // shipping_tier: 'next day, air,ground'
          items: gaProducts,
        },
        customEventParams: {
          totalDiscountAmount: discountValue,
          user_id: userId,
        },
      }
      this.addShippingInfo(eventData)

    } catch (e) {
      console.error(e)
    }

  }

  hasLoggedUserId(userId: string): void {
    const eventData: UserIdInterface = {
      event: 'user_id_insert',
      eventParams: {
        user_id: userId,
      },
      customEventParams: {
        user_id: userId,
      },
    }

    try {
      this.addUserInfo(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasAddedProductToCart(products: ItemInterfaceNew, userId?: string): void {
    try {
      const eventData: AddToCartEventInterface = {
        event: 'add_to_cart',
        eventParams: {
          currency: 'ZAR',
          value: products.price,
          items: [products],
        },
        customEventParams: {
          user_id: userId,
        },
      }
      this.addToCart(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasRemovedProductFromCart(products: ItemInterfaceNew, userId?: string): void {
    try {
      const eventData: RemoveFromCartInterface = {
        event: 'remove_from_cart',
        eventParams: {
          currency: 'ZAR',
          value: products.price,
          items: [products],
        },
        customEventParams: {
          user_id: userId,
        },
      }
      this.removeFromCart(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasViewedMultipleProducts(list: string, products: ItemInterfaceNew[], userId: string): void {
    const topThree = []
    const topThreeIds: string[] = []
    for (let i = 0; i < 3; i++) {
      if (products[i] !== undefined) {
        topThree.push(products[i])
        topThreeIds.push(products[i].item_id)
      }
    }

    try {
      const eventData: ViewItemInterface = {
        event: 'view_item',
        eventParams: {
          currency: 'ZAR',
          value: products[0].price,
          items: topThree,
        },
        customEventParams: {
          currentProductIds: topThreeIds,
          user_id: userId,
        },
      }

      this.viewItem(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasBegunCheckoutStep(step: string | number, option: string, products: readonly CartItemFragment[], totalValue: number, discountTitles: string, discountValue: number, userId: string): void {
    const totalQuantity = this.getCartProductQuantities(products)
    const value = totalValue-discountValue
    const gaProducts = this.createCartProducts(products)
    const eventData: CheckoutInterface = {
      event: 'begin_checkout',
      eventParams: {
        currency: 'ZAR',
        value,
        coupon: discountTitles,
        items: gaProducts,
      },
      customEventParams: {
        option,
        totalDiscountAmount: discountValue,
        user_id: userId,
        undiscountedPrice: totalValue,
        totalProductQuantity: totalQuantity,
        source: 'UCOOK FRONT END',
        method: 'Card',
      },
    }
    try {
      this.beginCheckout(eventData)
    } catch (error) {
      console.error(error)
    }

  }

  hasSavedCart(products: readonly CartItemFragment[], totalValue: number, discountTitles: string, discountValue: number, userId: string): void {
    const totalQuantity = this.getCartProductQuantities(products)
    const value = totalValue
    const gaProducts = this.createCartProducts(products)
    const eventData: CartSaveInterface = {
      event: 'cart_save',
      eventParams: {
        currency: 'ZAR',
        value,
        coupon: discountTitles,
        items: gaProducts,
      },
      customEventParams: {
        totalDiscountAmount: discountValue,
        user_id: userId,
        undiscountedPrice: totalValue + discountValue,
        totalProductQuantity: totalQuantity,
      },
    }
    try {
      this.saveCart(eventData)
    } catch (error) {
      console.error(error)
    }

  }

  hasAddedUserId(userId: string): void {

    try {
      const eventData: UserIdInterface = {
        event: 'user_id_insert',
        eventParams: {
          user_id: userId,
        },
      }

      this.addUserInfo(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasMadePurchase(step: string | number, option: string, products: readonly CartItemFragment[], id: string, revenue: number, discountTitles: string, discountValue: number, userId: string): void {

    const gaProducts = this.createCartProducts(products)
    const totalQuantity = this.getCartProductQuantities(products)
    try {
      const eventData: PurchaseInterface = {
        event: 'purchase',
        eventParams: {
          currency: 'ZAR',
          transaction_id: id,
          value: revenue,
          coupon: discountTitles,
          items: gaProducts,
        },
        customEventParams: {
          option,
          totalDiscountValue: discountValue,
          user_id: userId,
          totalProductQuantity: totalQuantity,
          source: 'UCOOK FRONT END',
          method: 'Card',
          retry: false,
        },
      }
      this.purchase(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasPerformedSearch(term: string): void {
    try {

      const eventData: SearchItemInterface = {
        event: 'search',
        eventParams: {
          term,
        },
      }

      this.search(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasSelectedItem(list: string, data: ItemInterfaceNew, userId: string): void {

    const eventData: SelectItemInterface = {
      event: 'select_item',
      eventParams: {
        item_list_name: list,
        items: [data],
      },
      customEventParams: {
        currentProductId: data.item_id,
        user_id: userId,
      },
    }
    try {
      this.selectItem(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasSelectedMultipleItems(list: string, data: ItemInterfaceNew[], userId: string): void {
    const topThree = []
    const topThreeIds: string[] = []
    for (let i = 0; i < 3; i++) {
      if (data[i] !== undefined) {
        topThree.push(data[i])
        topThreeIds.push(data[i].item_id)
      }
    }

    const eventData: SelectItemInterface = {
      event: 'select_item',
      eventParams: {
        item_list_name: list,
        items: topThree,
      },
      customEventParams: {
        currentProductIds: topThreeIds,
        user_id: userId,
      },

    }
    try {
      this.selectItem(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasSignedUp(email: string, mobile: string, method: string, firstName: string, lastName: string, newsletter: string, source: string): void {
    try {

      if (!!mobile && mobile?.startsWith('+')) {
        mobile = mobile.substring(1)
      }

      const cleanedNumber = mobile?.replace(/\s+/g, '')

      const eventData: SignupInfoInterface = {
        event: 'sign_up',
        eventParams: {
          email,
          mobile: cleanedNumber,
          method,
          firstName,
          lastName,
          newsletter,
          source,
        },
      }

      this.signUp(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasLoggedIn(userId: string, method: string, source: string): void {
    try {
      const eventData: LoginInfoInterface = {
        event: 'login',
        eventParams: {
          userId,
          method,
          source,
        },
      }

      this.login(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasGeneratedLead(email: string, mobile: string, method: string): void {
    try {
      const eventData: GenerateLeadInterface = {
        event: 'generate_lead',
        eventParams: {
          currency: 'ZAR',
          value: 1,
        },
        customEventParams: {
          email,
          mobile,
          method,
        },
      }

      this.generateLead(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasViewedProduct(list: string, products: ItemInterfaceNew, userId: string): void {

    try {
      const eventData: ViewItemInterface = {
        event: 'view_item',
        eventParams: {
          currency: 'ZAR',
          value: products.price,
          items: [products],
        },
        customEventParams: {
          currentProductId: products.item_id,
          user_id: userId,
        },
      }

      this.viewItem(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasClickedElement(elementName: string, elementPurpose?: string, userId?: string): void {
    try {
      const eventData: ClickElementInterface = {
        event: 'click_element',
        eventParams: {
          elementName,
        },
        customEventParams: {
          user_id: userId,
          elementPurpose,
        },
      }

      this.clickElement(eventData)
    } catch (e) {
      console.error(e)
    }
  }

  hasViewedCatalogue(products: ItemInterfaceNew[], userId: string): void {
    const productList: ItemInterfaceNew[] = []

    if (!products) {
      return
    }
    for (let i = 0; i < products.length; i++) {
      if (products[i] !== undefined) {
        productList.push({
          item_name: products[i].item_name,
          item_id: products[i].item_id,
          item_group_id: products[i].item_group_id,
          item_category: products[i].item_category,
          price: products[i].price,
          item_brand: products[i].item_brand,
          item_variant: products[i].item_variant,
          item_list_name: products[i].item_list_name,
          index: products[i].index,
          item_image: products[i]?.item_image,
        })
      }
    }

    try {

      const eventData: ItemListInterface = {
        event: 'view_item_list',
        eventParams: {
          item_list_name: products[0]?.item_list_name,
          items: productList,
        },
        customEventParams: {
          user_id: userId,
        },
      }
      this.viewItemList(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasAddedToCart(products: ItemInterfaceNew, userId?: string): void {

    try {
      const eventData: AddToCartEventInterface = {
        event: 'add_to_cart',
        eventParams: {
          currency: 'ZAR',
          value: products.price,
          items: [products],
        },
        customEventParams: {
          user_id: userId,
        },
      }
      this.addToCart(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasRemovedFromCart(products: ItemInterfaceNew, userId?: string): void {

    try {
      const eventData: RemoveFromCartInterface = {
        event: 'remove_from_cart',
        eventParams: {
          currency: 'ZAR',
          value: products.price,
          items: [products],
        },
        customEventParams: {
          user_id: userId,
        },
      }
      this.removeFromCart(eventData)
    } catch (e) {
      console.error(e)
    }

  }

  hasAddedCoupon(couponName: string, couponType: string): void {
    try {
      const eventData: CouponDetailsInterface = {
        event: 'coupon-insert',
        eventParams: {
          couponName,
          couponType,
        },
      }
      this.addCoupon(eventData)
    } catch (e) {
      console.error(e)
    }
  }

}
