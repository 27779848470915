import React, { useRef } from 'react'

import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Carousel, CarouselFlexContainer, CarouselNavIconColor, CarouselNavPositionEnum, getPageSectionBackground, ResponsivePXValue } from '@client/components'
import { PageBannerSectionFragment, useGetAppQuery, PageBannerFragment } from '@hooks/api'
import { DeviceTypeEnum, PageBannerVariationEnum } from '@uctypes/api/globalTypes'

import { PageBanner } from './PageBanner'
import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string, $height: string, $mobileHeight: string }>`
 display: flex;
 flex-direction: column;
 flex: 1;

 background: ${(props): CSS => props.$background};

 ${(props): CSS => (props?.$height) ? ResponsivePXValue('height', { mobile: props?.$mobileHeight, tablet: props?.$mobileHeight, desktop: props?.$height }) : null}
 ${(props): CSS => (props?.$height) ? ResponsivePXValue('min-height', { mobile: props?.$mobileHeight, tablet: props?.$mobileHeight, desktop: props?.$height }) : null}

`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}

  .page-banner {
    width: min(100vw, 1440px);
    margin: 0 !important;
  }

  .image-banner-progress-container-class {
    padding-top: 0;

    ${ResponsivePXValue('margin-top', '-16px')}
  }
`

export interface PageBannerSectionProps {
  pageSection: PageBannerSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageBannerSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageBannerSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const background = getPageSectionBackground(pageSection?.background)

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const maxWidth = isMobile ? '100vw' : '1440px'

  let displayNavButtons = true
  let displayProgress = false
  let displayPagingInfo = false
  let navPosition = CarouselNavPositionEnum.CENTER
  let autoPlay = false
  let loop = true
  let navButtonLeft = '24px'
  let navButtonRight = '24px'
  let progressContainerClass = ''
  let navIconColor: CarouselNavIconColor = 'white'

  const pageBannerVariation = pageSection?.pageBanners ? pageSection?.pageBanners[0]?.variation : PageBannerVariationEnum.HERO_BANNER

  if (pageBannerVariation === PageBannerVariationEnum.HERO_BANNER || pageBannerVariation === PageBannerVariationEnum.CAMPAIGN_BANNER) {
    autoPlay = true
  } else if (pageBannerVariation === PageBannerVariationEnum.CONTENT_BANNER) {
    displayProgress = isMobile
    displayPagingInfo = isMobile
    navPosition = isMobile ? CarouselNavPositionEnum.BOTTOM : CarouselNavPositionEnum.CENTER
    loop = false
  } else if (pageBannerVariation === PageBannerVariationEnum.IMAGE_BANNER) {
    navButtonLeft = '48px'
    navButtonRight = '48px'
    displayNavButtons = !isMobile
    displayProgress = true
    autoPlay = true
    navIconColor = 'grey'
    progressContainerClass = 'image-banner-progress-container-class'
  }

  let index: number
  let pageBanner: PageBannerFragment

  return (
    <Container ref={pageRef} $background={background} $height={pageSection?.bannerSectionHeight} $mobileHeight={pageSection?.mobileBannerSectionHeight}>
      <Choose>
        <When condition={!!pageSection}>
          <Choose>
            <When condition={pageSection?.pageBanners?.length === 1}>
              <PageBanner
                className='page-banner'
                pageBanner={pageSection.pageBanners[0]}
                key={index}
                height={pageSection?.bannerHeight}
                mobileHeight={pageSection?.mobileBannerHeight} />
            </When>
            <Otherwise>
              <CarouselContainer>
                <Carousel
                  trackingTitle={`${pageSection?.title}-page-banner-carousel`}
                  maxWidth={maxWidth}
                  autoPlay={autoPlay}
                  navButtonLeft={navButtonLeft}
                  navButtonRight={navButtonRight}
                  navIconColor={navIconColor}
                  displayNavButtons={displayNavButtons}
                  displayProgress={displayProgress}
                  displayPagingInfo={displayPagingInfo}
                  navPosition={navPosition}
                  slidesToScroll={1}
                  loop={loop}
                  progressContainerClass={progressContainerClass}>
                  <For each='pageBanner' of={pageSection?.pageBanners || []}>
                    <PageBanner className='embla-slide page-banner'
                      pageBanner={pageBanner}
                      key={pageBanner.id}
                      height={pageSection?.bannerHeight}
                      mobileHeight={pageSection?.mobileBannerHeight} />
                  </For>
                </Carousel>
              </CarouselContainer>
            </Otherwise>
          </Choose>
        </When>
        <Otherwise>
          Page Banner Section not found
        </Otherwise>
      </Choose>
    </Container>
  )
}
