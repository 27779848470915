import React, { useRef } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CarouselFlexContainer, Heading, PageCardStacked, PageSectionPadding, ResponsivePXValue, Spacer } from '@client/components'
import { PageCardSectionFragment, useGetAppQuery, PageCardFragment } from '@hooks/api'
import { DeviceTypeEnum, PageSectionBackgroundEnum } from '@uctypes/api/globalTypes'

import { Carousel } from '../misc/Carousel/Carousel'

import { PageCard } from './PageCard'
import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string, $height?: string, $mobileHeight?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${(props): string => props.$background ? props.$background : props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('max-width', { mobile: '100%' })}
  overflow-x: hidden;

  ${PageSectionPadding}  
`

const StackedPageCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`
const PageCardContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;

  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '90vw', desktop: '1136px' }, { desktop: true })}

  .page-card {
    margin: 0 4px !important;
  }
`
const CarouselContainer = styled.div`
  ${CarouselFlexContainer}
  
  .embla-slide {
    margin: 0 4px !important;
  }
`

export interface PageCardSectionProps {
  pageSection: PageCardSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageCardSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageCardSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const theme = useTheme()

  const background = pageSection?.background === PageSectionBackgroundEnum.WHITE ? theme.colors.whites.pureWhite : theme.colors.whites.desertStorm
  const slidesToScroll = isDesktop ? 4 : 1
  const pageCards = pageSection?.pageCards || []
  const displayStacked = pageSection?.mobileShouldStack && !isDesktop

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  let pageCard: PageCardFragment

  return (
    <Container ref={pageRef} $background={background} $height={pageSection?.cardSectionHeight} $mobileHeight={pageSection?.mobileCardSectionHeight}>
      <Choose>
        <When condition={!!pageSection}>
          <If condition={!!pageSection?.title}>
            <Heading align='center' variant='h4' fontWeight={500} color={theme.colors.greys.liteCodGrey}>{pageSection.title}</Heading>
            <Spacer universal='32px' />
          </If>
          <Choose>
            <When condition={pageSection?.pageCards?.length === 1}>
              <PageCardContainer>
                <PageCard
                  className='page-card'
                  pageCard={pageSection.pageCards[0]}
                  height={pageSection?.cardHeight}
                  mobileHeight={pageSection?.mobileCardHeight} />
              </PageCardContainer>
            </When>
            <When condition={isDesktop && pageSection?.pageCards?.length < 4}>
              <PageCardContainer>
                <For each='pageCard' of={pageCards}>
                  <PageCard
                    className='page-card'
                    pageCard={pageCard}
                    key={pageCard.id}
                    height={pageSection?.cardHeight}
                    mobileHeight={pageSection?.mobileCardHeight}
                  />
                </For>
              </PageCardContainer>
            </When>
            <Otherwise>
              <Choose>
                <When condition={displayStacked}>
                  <StackedPageCardContainer>
                    <For each='pageCard' of={pageCards}>
                      <PageCardStacked
                        pageCard={pageCard}
                        key={pageCard.id}
                      />
                    </For>
                  </StackedPageCardContainer>
                </When>
                <Otherwise>
                  <CarouselContainer>
                    <Carousel
                      trackingTitle={`${pageSection?.title}-page-card-section-carousel`}
                      displayNavButtons={isDesktop}
                      slidesToScroll={slidesToScroll}
                      skipDesktop>
                      <For each='pageCard' of={pageCards}>
                        <PageCard
                          className='embla-slide'
                          pageCard={pageCard}
                          key={pageCard.id}
                          height={pageSection?.cardHeight}
                          mobileHeight={pageSection?.mobileCardHeight}
                        />
                      </For>
                    </Carousel>
                  </CarouselContainer>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </When>
        <Otherwise>
          Page Card Section not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
